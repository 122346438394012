<template>
  <div>
    报名评审
  </div>
</template>

<script>
export default {
  name: "Scores"
}
</script>

<style scoped>

</style>